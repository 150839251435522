<template>
  <div class="page">
    <div class="page-main">
      <el-form
        class="page-card"
        ref="form"
        :model="form"
        :rules="rules"
        label-width="auto"
      >
        <el-form-item
          prop="uploadFile"
          class="form-item-upload-file"
        >
          <div class="form-item-width">
            <el-upload
              class="upload"
              drag
              ref="upload"
              action="/common/upload"
              name="file"
              :headers="{ 'Accept-Language': language == 'en' ? 'en-US' : language }"
              :before-upload="beforeUpload"
              :on-change="onChange"
              :file-list="form.uploadFile"
              :on-remove="onRemove"
              :on-success="onSuccess"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                {{ $t('upgrade.tip1') }}
                <em>{{ $t('components.upload.tip2') }}</em>
              </div>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="footer-btns">
            <el-button
              class="footer-btn"
              type="primary"
              @click="submit"
              :disabled="!uploadFileState"
            >{{ $t('upgrade.upgrade') }}</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { upgrade } from '@/api/upgrade'
import { fileCheck } from '@/plugins/methods'

export default {
  name: 'Upgrade',
  data() {
    var uploadFileValidate = (rule, value, callback) => {
      if (this.uploadFileState) {
        callback()
      } else {
        callback(new Error(this.$t('firmware.uploadFileValidate')))
      }
    }
    return {
      loading: false,
      uploadFileState: false,
      uploading: false,
      form: {
        uploadFile: [],
        filename: '',
      },
      rules: {
        uploadFile: [
          {
            required: true,
            message: this.$t('firmware.uploadFileRule'),
            trigger: 'change',
          },
          { validator: uploadFileValidate, trigger: 'blur' },
        ],
      }
    }
  },
  computed: {
    ...mapState('memory', ['language'])
  },
  beforeRouteLeave(to, from, next) {
    if (!this.uploading || to.params.type == 'cancel') {
      next()
    } else {
      this.$message.error(this.$t('firmware.uploadFileValidate'))
    }
  },
  methods: {
    // 保存
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          console.log(this.form.filename);
          upgrade({ filename: this.form.filename })
            .then(() => {
              this.$store.commit('session/SET_DIALOG_BACKUP_STATUS_INFO', {
                code: 120000,
                message: this.$t('upgrade.upgradingTip')
              })
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    beforeUpload(file) {
      this.uploadFileState = false
      this.$refs.form.validateField('uploadFile')
      const goodFileType = fileCheck({
        file,
        fileTypes: ['.tar.gz'],
        fileSize: 5000 * 1024 * 1024,
        callback: (typeErr, sizeErr) => {
          typeErr && this.$message.error(this.$t('components.upload.typeTipsMsg', ['.tar.gz']))
          !typeErr &&
            sizeErr &&
            this.$message.error(this.$t('components.upload.sizeTipsMsg', ['5000M']))
        },
      })
      if (goodFileType) {
        this.uploadFileState = false
        this.uploading = true
      }
      return goodFileType
    },
    onChange(file, fileList) {
      this.form.uploadFile = [fileList[fileList.length - 1]]
    },
    onRemove(file, fileList) {
      this.uploadFileState = false
      this.uploading = false
      this.form.uploadFile = fileList
      this.$refs.form.validateField('uploadFile')
      this.$refs.upload.abort()
    },
    onSuccess(response) {
      if (response.code === 10000) {
        this.$message.error(response.message)
        this.form.uploadFile = []
        this.uploadFileState = false
      }else{
        this.form.filename = response.result.rows[0].filename
        this.uploadFileState = true
      }
      this.uploading = false
      this.$refs.form.validateField('uploadFile')
    },
  },
}
</script>

<style lang="scss" scoped>
.form-item-width {
  display: flex;
  justify-content: center;
  .upload {
    ::v-deep .el-upload-dragger{
      width: 50rem;
    }
  }
}
</style>
